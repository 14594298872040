import { CONTENT_1824, CONTENT_DD, CONTENT_WH } from '@/utils/constants';

/**
 * Function to get WEB_ENV or STORYBOOK_WEB_ENV environment variable value
 * if WEB_ENV and STORYBOOK_WEB_ENV does not exists, it will return 'wh' (short for westholme) as default value.
 */
export function getWebEnv() {
  if (process.env.WEB_ENV) {
    return process.env.WEB_ENV;
  } else if (process.env.STORYBOOK_WEB_ENV) {
    return process.env.STORYBOOK_WEB_ENV;
  }
  return 'wh';
}

export const isDDEnv = () =>
  process.env.WEB_ENV === 'dd' || process.env.NEXT_PUBLIC_WEB_ENV === 'dd';
export const isWHEnv = () =>
  process.env.WEB_ENV === 'wh' || process.env.NEXT_PUBLIC_WEB_ENV === 'wh';
export const is1824Env = () =>
  process.env.WEB_ENV === '1824' || process.env.NEXT_PUBLIC_WEB_ENV === '1824';

export const apiBaseUrl = () => {
  return process.env.NEXT_PUBLIC_APP_ENV === 'local'
    ? 'http://localhost:7071'
    : '';
};

export const getOriginSiteUrl = (brand) => {
  switch (brand) {
    case 'aaco':
      return 'https://aaco.com.au/';
    case 'wh':
      return 'https://www.westholme.com/';
    case 'dd':
      return 'https://www.darlingdownswagyu.com/';
    case '1824':
      return 'https://www.1824.com.au/';
  }
};

export const canChangeBrand = ({ brand, accessLevel = {} } = {}) => {
  if (!accessLevel) return false;
  if (!brand) return false;
  if (brand === 'aaco') return false;
  return !!accessLevel[brand];
};

export const getBrandContent = (brand) => {
  if (brand == 'wh') return CONTENT_WH;
  if (brand == '1824') return CONTENT_1824;
  if (brand === 'dd') return CONTENT_DD;
  return CONTENT_DD;
};

export const getBrandSelectorOptions = ({ accessLevel = {} } = {}) => {
  const options = [];
  if (accessLevel.dd) {
    options.push({ label: 'DARLING DOWNS PORTAL', value: 'dd' });
  }
  if (accessLevel.wh) {
    options.push({ label: 'WESTHOLME PORTAL', value: 'wh' });
  }
  if (accessLevel['1824']) {
    options.push({ label: '1824 PORTAL', value: '1824' });
  }
  return options;
};

export const isDDBrand = (brand) => brand === 'dd';
export const isWHBrand = (brand) => brand === 'wh';
export const is1824Brand = (brand) => brand === '1824';
